import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class MonacoService {
	private _schemas: { [p: string]: any } = {};
	private _model: { [p: string]: any } = {};

	addJsonSchema(uri: string, schema: any) {
		const monaco = (window as any).monaco;

		if (!monaco || this._schemas[uri]) {
			return this._model[uri];
		}

		const uris = monaco.Uri.parse(uri);
		this._schemas[uri] = schema;
		this._model[uri] = monaco.editor.createModel('', 'json', uris);

		monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
			validate: true,
			schemas: Object.keys(this._schemas).map((uri) => ({
				uri,
				fileMatch: [this._model[uri].uri].toString(),
				schema: this._schemas[uri],
			})),
		});

		return this._model[uri];
	}
}
